@import '../_variables';
@import '_mixins';
@import '_icons-mixins';

.icon-logo();

.product-list {
  padding-left: 0;
  list-style: none;

  &.slider {
    .slider-spacing();
  }
}

.product-list-3,
.product-list-4,
.product-list-5,
.product-list-6,
.product-list-8 {
  @media (@max-screen-xs) {
    > * {
      flex: 0 0 50%;
    }
  }
}

.product-list-3,
.product-list-4,
.product-list-5,
.product-list-6 {
  @media (@min-screen-xs) and (@max-screen-sm) {
    > * {
      flex: 0 0 33.333%;
    }
  }
}

.product-list-8 {
  @media (@min-screen-xs) {
    > * {
      flex: 0 0 25%;
    }
  }
}

@media (@min-screen-sm) {
  .product-list-3 > *, .product-list-6 > * {
    flex: 0 0 33.333%;
  }

  .product-list-4 > * {
    flex: 0 0 25%;
  }

  .product-list-5 > * {
    flex: 0 0 20%;
  }
}

@media (@min-screen-md) {
  .product-list-8 > * {
    flex: 0 0 12.5%;
  }
}

@media (@min-screen-lg) {
  .product-list-6 > * {
    flex: 0 0 16.6666%;
  }
}

.product-list:not(.slider) {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  left: -.5rem;
  right: -.5rem;
  width: calc(100% + 1rem);

  &.product-list-placeholder {
    p, .aspect {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        background-color: @color-default-1;
        left: 0;
        bottom: 0;
      }
    }

    .aspect:after {
      top: 0;
      width: 100%;
    }

    p:after {
      top: .5rem;
      width: 60%;
    }

    .product-list-item-title:after {
      width: 90%;
    }

    .product-list-item-price:after {
      width: 45%;
    }
  }

  > * {
    padding: 0 .5rem 0;
  }

  @media (@min-screen-sm) {
    &.product-list-gap-x-2 {
      left: -1rem;
      right: -1rem;
      width: calc(100% + 2rem);

      > * {
        padding: 0 1rem 0;
      }
    }

    &.bigger-product-list li {
      flex: 0 0 33.333%;
    }
  }

  &.product-list-gap-y-1 > * {
    padding-bottom: 1rem;
  }

  &.product-list-gap-y-2 > * {
    padding-bottom: 2rem;
  }

  &.product-list-gap-y-4 > * {
    padding-bottom: 4rem;
  }
}

.product-horizontal-list {
  list-style: none;
  display: flex;

  @media (pointer: coarse) {
    position: relative;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-left: 1rem;
    padding-bottom: 1rem;
    margin-left: -1rem;
    width: calc(100% + 2rem);

    > * {
      flex: 0 0 calc(50% - 2rem);
    }
  }

  @media (pointer: fine) {
    padding-left: 0;
    flex-wrap: wrap;
    overflow-x: hidden;
    position: relative;
    left: -1rem;
    right: -1rem;
    width: calc(100% + 2rem);

    > * {
      flex: 0 0 50%;
    }
  }

  > * {
    margin-right: 1rem;
  }

  &::after {
    content: '';
    flex: 0 0 1px;
  }

  @media (@min-screen-sm), (pointer: fine) {
    padding-left: 0;

    &::after {
      content: none;
    }
  }

  @media (@min-screen-sm), (pointer: fine) {
    > * {
      padding: 0 1rem;
      margin-right: 0;
    }
  }
}

.product-horizontal-list-3,
.product-horizontal-list-4,
.product-horizontal-list-6 {
  @media (@min-screen-xs) {
    > * {
      flex: 0 0 33.333%;
    }
  }
}

.product-horizontal-list-4,
.product-horizontal-list-6 {
  @media (@min-screen-xs) and (pointer: coarse) {
    > * {
      flex: 0 0 calc(33.333% - 2rem);
    }
  }
}

.product-horizontal-list-4 {
  @media (@min-screen-sm) {
    > * {
      flex: 0 0 25%;
    }
  }
}

.product-horizontal-list-6 {
  @media (@min-screen-md) {
    > * {
      flex: 0 0 16.6666%;
    }
  }
}

.product-horizontal-list-5 {
  @media (@min-screen-sm) {
    > * {
      flex: 0 0 20%;
    }
  }
}

.product-horizontal-list-8 {
  @media (@min-screen-xs) {
    > * {
      flex: 0 0 25%;
    }
  }

  @media (@min-screen-md) {
    > * {
      flex: 0 0 12.5%;
    }
  }
}

#product-history-list > * {
  flex: 0 0 50%;
}

@media (@min-screen-sm) {
  #product-history-list > * {
    flex-basis: 25%;
  }
}

@media (@min-screen-md) {
  #product-history-list > * {
    flex-basis: 14.2857142857143%;
  }
}